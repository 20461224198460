// EXEMPT
import React, { useEffect, useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import type { ChampionBuild } from "@/data-models/contentful.mjs";
import ChampionBuilds from "@/game-lol/components/ChampionBuilds.jsx";
import type { CHAMPIONS } from "@/game-lol/constants/champion-classes.mjs";
import { CHAMPIONS_KEY_TO_ID } from "@/game-lol/constants/champion-classes.mjs";
import { ROLE_SYMBOL_TO_STR } from "@/game-lol/constants/constants.mjs";
import lolFetchChampionData from "@/game-lol/fetches/lol-fetch-champion-data.mjs";
import type { ChampionsStats } from "@/game-lol/models/lol-champions-stats.mjs";
import { getFallbackRole } from "@/game-lol/utils/champions-stats-utils.mjs";
import useStaticChampionByKey from "@/game-lol/utils/use-static-champion-by-id.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

type PropEnforcedActuals = {
  className: string;
  champion: {
    name: string;
  };
  championId: keyof typeof CHAMPIONS;
  matchupChampionId: null;
  championRole: ChampionBuild["role"] | null;
  queue: ChampionBuild["queue"];
  region: ChampionBuild["region"];
};

export default function ProbuildsChampBuilds(props: ChampionBuild) {
  const champion = useStaticChampionByKey(props.champion);
  const {
    lol: { championStats },
  } = useSnapshot(readState);
  const championId = useMemo(
    () => CHAMPIONS_KEY_TO_ID[props.champion] || champion.id,
    [champion.id, props.champion],
  );
  const ephemeralParams = useMemo(() => {
    const url = new URL(appURLs.BLITZ);
    url.searchParams.set("queue", props.queue);
    url.searchParams.set("region", props.region);
    url.searchParams.set("tier", props.tier);
    url.searchParams.sort();
    return url.searchParams;
  }, [props.queue, props.region, props.tier]);
  const role = useMemo(() => {
    const s = championStats[championId]?.[ephemeralParams.toString()] || [];
    return (
      (props.role ||
        ROLE_SYMBOL_TO_STR[
          getFallbackRole(
            (s instanceof Error ? [] : s) as unknown as ChampionsStats,
          )
        ]?.gql) ??
      null
    );
  }, [championId, championStats, ephemeralParams, props.role]);
  useEffect(() => {
    if (champion?.id) lolFetchChampionData([champion.id], ephemeralParams);
  }, [champion, ephemeralParams]);
  if (!champion) return null;
  return React.createElement(ChampionBuilds, {
    className: "fullbleed",
    champion: champion,
    championId,
    matchupChampionId: null,
    championRole: role,
    queue: props.queue,
    region: props.region,
  } as PropEnforcedActuals);
}
